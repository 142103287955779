import axios from 'axios'

class Nation {
  constructor (index, location, name, iconURL, iconAnchor, iconSize) {
    this.index = index
    this.location = location
    this.name = name
    this.iconURL = iconURL
  }
}

// Array of local data of Nations
var Nations = [
  new Nation(0, [59.856217, 17.636733], 'GH', 'img/icons/ghnation.png'),
  new Nation(1, [59.859750, 17.634572], 'Gotlands', 'img/icons/gotlands.png'),
  new Nation(2, [59.859459, 17.630089], 'Göteborgs', 'img/icons/goteborgs.png'),
  new Nation(3, [59.859030, 17.627115], 'Kalmars', 'img/icons/kalmar.png'),
  new Nation(4, [59.857271, 17.637667], 'Norrlands', 'img/icons/norrlands.png'),
  new Nation(5, [59.859215, 17.631123], 'Smålands', 'img/icons/smalands.png'),
  new Nation(6, [59.859014, 17.630662], 'Snerikes', 'img/icons/snerikes.png'),
  new Nation(7, [59.856512, 17.634265], 'Stocken', 'img/icons/stocken.png'),
  new Nation(8, [59.859956, 17.629859], 'Uplands', 'img/icons/uplands.png'),
  new Nation(9, [59.860368, 17.628646], 'V-Dala', 'img/icons/vdala.png'),
  new Nation(10, [59.856747, 17.638660], 'VG', 'img/icons/vgnation.png'),
  new Nation(11, [59.857197, 17.633817], 'Värmlands', 'img/icons/varmlands.png'),
  new Nation(12, [59.855272, 17.638357], 'ÖG', 'img/icons/ognation.png'),
  new Nation(13, [59.857983, 17.630536], 'Skånes', 'img/icons/skane.png')
]

var Classes = [
  // 'Fa',
  // 'Fb',
  // 'Fc',
  // 'Fd'
  'F1',
  'F2',
  'F3',
  'F4',
  'F5',
  'F6'
]

var Times = [
  '17:45',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30'
]

// Setup location watch, given an LMap
function SetupWatchPosition (map, onLocationFound) {
  map.locate({ setView: false, watch: true })

  map.on('locationfound', (e) => {
    if (e.latlng != null) {
      onLocationFound(e.latlng)
      console.log('located')
      PushLocationToServer(e.latlng)
    }
  })
}

function PushLocationToServer (position) {
  var uname = localStorage.username
  if (uname != null) {
    // axios.get('https://futf.se/API/updatePosition', { params: {
    //   lat: position.lat,
    //   long: position.lng,
    //   uname: uname
    // } })
    // axios.get('https://futf.se/API/updatePosition?lat=' + position.lat + '&long=' + position.lng + '&uname=' + uname)
    // Temporary solution to the weird null post bug
    if (position.lat != '' && position.lng != '') {
      axios.put('https://api.futf.se/api/user/updatePosition.php', {
        username: uname,
        latitude: position.lat,
        longitude: position.lng
      })
    }
  }
}

// Get the name of a position, given coordinates and a radius
function GetNamedPosition (location, threshholdRadius) {
  Nations.forEach(function (element) {
    if (Math.pow(location[0] - element.location[0], 2) + Math.pow(location[1] - element.location[1], 2) < Math.pow(threshholdRadius, 2)) {
      return element.name
    }
    return 'Unknown location'
  })
}

function GetCurrentTime () {
  var time = new Date()
  var hours = time.getHours()
  hours = hours < 10 ? '0' + hours : hours
  var minutes = time.getMinutes()
  minutes = minutes < 10 ? '0' + minutes : minutes
  return hours + ':' + minutes
}

export { Nations, Classes, Times, GetNamedPosition, SetupWatchPosition, GetCurrentTime }
